import { computed, type Ref } from "vue";
import type { IField } from "~/entities/field";
import { useCollectionFields } from "./useCollectionFields";

export const useCollectionFieldsIncluding = (
  collectionName: Ref<string>,
  fieldNames: string[],
) => {
  const { fields: collectionFields } = useCollectionFields(collectionName);

  const fields = computed<Record<string, IField>>(() => {
    const map: Record<string, IField> = {};

    for (const fieldName of fieldNames) {
      if (!(fieldName in collectionFields.value)) continue;
      map[fieldName] = collectionFields.value[fieldName];
    }

    return map;
  });

  return { fields };
};
